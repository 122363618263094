<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'product',
  data() {
    return {}
  },
  created() {
  },
}
</script>
