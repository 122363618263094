<template>
  <v-container fluid grid-list-lg>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <Edit :editDialog="editDialog" @close="editDialog = false" @submit="getList" :editCategory="selected"></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle :access="checkRightStatus(8)" title="Category" @add="add" />

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
            hide-details clearable></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex shrink>
          <v-switch color="yellow darken-1" v-model="singleExpand" label="Single expand" class="mt-2"></v-switch>
        </v-flex>
      </v-card-title>
      <v-data-table :headers="headers" :items="categorys" :search="search" :loading="loading" :sort-by="sortBy"
        :page.sync="page" :single-expand="singleExpand" :expanded.sync="expanded" show-expand
        :items-per-page="itemsPerPage">
        <template v-slot:item="{ item, isExpanded, expand }">
          <tr class="pointer">
            <td class="text-left">
              <v-btn @click="expand(true)" icon v-if="!isExpanded">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn @click="expand(false)" icon v-if="isExpanded">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </td>
            <td class="text-left">
              <ImageIcon :item="item" type="product" />
              <!-- <v-avatar tile class="mt-2 mb-2 mr-2">
                  <v-img v-if="item.imageUrl" :src="item.imageUrl"> </v-img>
                </v-avatar> -->
              {{ item.name }}
            </td>
            <td class="text-left">
              {{ item.productBalance }}
            </td>
            <td class="text-left">
              {{ item.note }}
            </td>
            <td class="justify-end align-center text-right">
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item v-if="checkRightStatus(24)" @click="editCategory(item)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="checkRightStatus(8)" @click="deleteCategory(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-card color="#F3F8FC" flat tile>
              <v-card-text class="title">
                <PhoneList :CategoryId="item.id"></PhoneList>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
          results.</v-alert>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import categroyService from "@/modules/Product/Category/service.js";
import PhoneList from "@/modules/Product/components/PhoneList";
import ImageIcon from "@/components/Image/ImageIcon.vue";
import { mapGetters } from "vuex";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  name: "category-list",
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selected: null,
      expanded: [],
      singleExpand: true,
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: this.$t("labels.name"),
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Stock",
          align: "left",
          value: "productBalance",
          sortable: true,
        },
        {
          text: this.$t("labels.description"),
          align: "left",
          value: "note",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      categorys: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - Category";
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  components: {
    Create,
    PhoneList,
    ImageIcon,
    Edit,
  },
  methods: {
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      return categroyService.getAll().then((response) => {
        this.loading = false;
        // console.log("categorys", response);
        this.categorys = response.data;
        return response;
      });
    },
    editCategory(category) {
      this.selected = category;
      this.editDialog = !this.editDialog;
    },
    deleteCategory(category) {
      if (category.productCount > 0) {
        this.$swal({
          title: "Delete Category",
          text: `${category.name} is used in ${category.productCount} product(s) change from product before you delete this category`,
          icon: "warning",
        });
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            categroyService.delete(category.id).then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Category has been deleted.", "success");
                this.getList();
              }
            });
          }
        });
      }
    },
  },
};
</script>
